import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2><a parentName="h2" {...{
        "href": "https://crossfittheville.files.wordpress.com/2011/03/imagescabz4873.jpg"
      }}><img parentName="a" {...{
          "src": "https://crossfittheville.files.wordpress.com/2011/03/imagescabz4873.jpg?w=615",
          "alt": null,
          "title": "imagesCABZ4873"
        }}></img>{`{.alignnone .size-full .wp-image-101}`}</a><a parentName="h2" {...{
        "href": "https://crossfittheville.files.wordpress.com/2011/03/imagescaa1glma.jpg"
      }}><img parentName="a" {...{
          "src": "https://crossfittheville.files.wordpress.com/2011/03/imagescaa1glma.jpg?w=615",
          "alt": null,
          "title": "imagesCAA1GLMA"
        }}></img>{`{.alignnone .size-full .wp-image-102}`}</a></h2>
    <h2>{`Globo gym vs CrossFit gym`}</h2>
    <div>
      <p>{`I visited a Crossfit affiliate for the first time ever this weekend.
Allow me to compare and contrast a first-time Crossfit visit vs. a
first-time Globo gym visit. Globo first:`}</p>
      <ul>
        <li parentName="ul">{`Enter gym. Notice front desk employee sitting in front of a
computer. Wait about 15-20 seconds for them to stop playing
solitaire or Facebook.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`“Hi, I’m Brian. This is my first time here.” “Oh, let me get a
“trainer” to show you around.”`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`Walk through the gym. Trainer shows you the “cardio areas”, which
consist of rows upon rows of treadmills, ellipticals, and stationary
bikes.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`Next, you are shown the “machines areas”. Various contraptions with
diagrams that highlight whatever muscle group the thing is supposed
to isolate. If you’re lucky, they’ll have Hammer Strength stuff!!
You may get a line like “This machine is designed to work you
exactly like free weights.” You think to yourself, “Hmmm, well why
not just do free weights, then?” You also note that at least 2 of
the four walls in every area are made up entirely of mirrors. And
people are standing and looking at themselves in them.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`The tour continues: “Here we have the free weight area. You can use
this if you want to get hyoooge.” This typically equates to the
square footage of a walk-in closet. Work boots, little tank-tops,
and multiple shakers full of
`}<a parentName="li" {...{
            "href": "http://rehobothcrossfit.com/nutrition-diet-recipes/supplements/",
            "title": "supplements"
          }}>{`supplements`}</a>{`
abound.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`Now, the all-important retreat into a tiny office where you get the
used-car sale. Something like, “Well, typically our memberships are
\\$59/mo, plus a \\$269 sign-up fee. We’ll waive the fee and set you
up on a tiered payment plan where every year the fee will drop
11.59043% until you reach year 4, at which point…” blah blah blah.
You counter with, “Well my friend pays \\$20/mo with no sign-up fee.”
They respond, “I’ll have to get my manager.” The dance continues.
You threaten to walk out. At this point, you may or may not get the
“super secret pricing deal”. You realize you’ve lost a little bit of
your soul. They throw in free tanning.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`You are now a member. You work out for 2 years. You see the same
people every day. You never talk to them. After 2 years you notice
that they all look exactly the same as the first day you ever saw
them, despite the fact that they spend at least an hour a day on the
elliptical while
`}<a parentName="li" {...{
            "href": "http://rehobothcrossfit.com/library/",
            "title": "reading"
          }}>{`reading`}</a>{` a magazine
or talking on their cell phones.`}</li>
      </ul>
      <p><strong parentName="p">{`OK, the Crossfit gym first-time experience:`}</strong></p>
      <ul>
        <li parentName="ul">{`The first thing you notice is that there really isn’t a front door
— it’s a garage door. Or two. Did this place used to be an auto
shop?`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`The next thing you notice are people on the floor gasping for air.
You can see this clearly as the whole place is one open room and the
garage doors are open.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`You walk in. At first, you’re not quite sure who works there and who
works out there — they all look about the same. Eventually, a
trainer or owner finds you and greets you enthusiastically.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`You’re invited to take part in a workout. As you wait for the
current group to finish up, you take notice of your surroundings.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`No air conditioning. No mirrors. Not a single treadmill. Rowers —
uh oh.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`Ropes and gymnastics rings hanging from the ceiling. Kettlebells.
Weight racks. Weird rubber weights. Lots and lots and lots of places
to do pull-ups. Instead of mirrors, the walls are covered with
dry-erase boards. And peoples names are listed under various
workouts that have girls’ names as the titles. Obviously people
compete over everything here. As people finish the workout and peel
themselves off the floor, you notice that they all seem to be
friends.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`OK, time for the workout. A trainer takes you and whoever else is
there for a first-time visit and you do some stretches, and then
they actually teach you how to do whatever you’re going to be doing
that day. Real
`}<a parentName="li" {...{
            "href": "http://rehobothcrossfit.com/media/videos/health/",
            "title": "instruction"
          }}>{`instruction`}</a>{`
(with PVC pipe), and the guy (or girl) actually sounds like they
know what they’re talking about.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`Workout time. 3-2-1-go. 1 minute into the workout you realize that
you actually might die before it’s done. You regret eating whatever
it was you ate for breakfast, because you’re pretty sure everybody
is going to see it coming out of your mouth. 7 minutes later you’re
finished, and soaked with sweat on a pile on the floor. You think to
yourself that you did more work in 7 minutes than most people at
your old globo did in a month.`}</li>
      </ul>
      <pre><code parentName="pre" {...{
          "className": "language-{=html}"
        }}>{`<!-- -->
`}</code></pre>
      <ul>
        <li parentName="ul">{`After the workout, you’re told you can hang around and ask questions
or go home and ice yourself down. No pitch.`}</li>
      </ul>
      <p>{`Now, which one sounds better? I’m pretty sure I have not exaggerated
anything here — these are my actual experiences. I’m also joining the
Crossfit gym.`}</p>
      <p>{`Taken from CrossFit Rehoboth..So True so very true.`}</p>
      <p>{`Do you have a story of your experience at a Globo Gym, Please share.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      